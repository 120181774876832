// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable";
import "regenerator-runtime/runtime";
require("@rails/ujs").start()
import "jquery"
import "bootstrap"
import "@hotwired/turbo-rails"

// 
// require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("@hotwired/turbo-rails")
require("ion-rangeslider")
// require("@googlemaps/markerwithlabel")
var card = require("card");

import LogRocket from 'logrocket';
LogRocket.init('wkgzl7/bennington-properties');

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import debounced from 'debounced'
debounced.initialize()


console.log('hello from webpacker')
// import { MarkerWithLabel } from "@googlemaps/markerwithlabel"
// var MarkerWithLabel = require('@googlemaps/markerwithlabel');
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


document.addEventListener("DOMContentLoaded", function (event) {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});
import "controllers"

import initVueApp from "./hello_vue.js"


initVueApp()

